@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "~react-horizontal-scrolling-menu/dist/styles.css";

@font-face {
  font-family: 'Frutiger';
  src: url('./assets/fonts/Frutiger/FrutigerPro47LightCondensed/font.woff2') format('woff2'),
    url('./assets/fonts/Frutiger/FrutigerPro47LightCondensed/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger';
  src: url('./assets/fonts/Frutiger/FrutigerPro65Bold/font.woff2') format('woff2'),
    url('./assets/fonts/Frutiger/FrutigerPro65Bold/font.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/poppins-bold-webfont.woff') format('woff'),
    url('./assets/fonts/Poppins/poppins-bold-webfont.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
}
*,
body,
:root {
  margin: 0;
  font-family: 'Frutiger', 'Arial', sans-serif, -apple-system, system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Frutiger', 'Arial', sans-serif, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
